import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
//import Headings from "./headings"
import Markdown from "react-markdown"

const PageAdvert = ({ advert, bg = "uk-background-muted" }) => {
  //console.log("PageAdvert - advert: ", advert)
  return (
    <>
      {advert.style === "image-right" ? (        
        <section className={`uk-section ${bg} uk-padding-remove`}>
          <div style={{ marginTop: "38px", marginBottom: "38px"}}>
            <div className="uk-container" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <div className={`uk-grid uk-child-width-1-2@l uk-flex-middle`}>
                  <div>
                    <div className="uk-margin-small-bottom">
                      <h4 className="uk-margin-remove" style={{marginBottom: "20px"}}>{advert?.title}</h4>
                    </div>
                    <div className=" uk-margin-small-bottom">
                      <h5 className="uk-margin-remove" style={{ color: "#687169", fontWeight: "500" }} >{advert?.heading}</h5>
                    </div>
                    <div className="uk-margin-small-top">
                      <Markdown children={advert?.content?.data?.content}/>
                    </div>
                    {advert.enabled === true ? (
                      <>
                      <Link
                          to={advert?.link}
                          alt={`button that links to our ${advert?.title}`}
                          title={`Click here to learn more about ${advert?.title}`}
                          className="uk-button uk-button-default uk-button-small uk-text-small uk-visible@l"
                          //data-uk-icon="arrow-right"
                          style={{ borderColor: "#617F7F", color: "#617F7F", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}

                        >
                          {advert?.buttonName}
                          {/* <h5 className="uk-margin-small-right buttonLink">
                            {advert?.buttonName}
                          </h5> */}
                        <span
                          className="uk-margin-small-left"
                          uk-icon="icon: arrow-right; ratio: 1"
                        ></span>                     
                      </Link>

                      <Link
                          to={advert?.link}
                          alt={`button that links to our new ${advert?.title}`}
                          title={`Click here to learn more about ${advert?.title}`}
                          className="uk-button uk-button-default uk-button-small uk-text-small uk-hidden@l"
                          //data-uk-icon="arrow-right"
                          style={{ borderColor: "#617F7F", color: "#617F7F", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}

                        >
                          {advert?.buttonName}
                            {/* <h5 className="uk-margin-small-right buttonLink">
                              {advert?.buttonName}
                            </h5> */}
                          <span
                            className="uk-margin-small-left"
                            uk-icon="icon: arrow-right; ratio: 1"
                          >
                          </span>                       
                      </Link>
                      </>                    
                    ) : null }
                  </div>
                    <div data-uk-scrollspy="cls: uk-animation-fade; delay: 700, repeat: true">
                      {advert?.image && (
                        <GatsbyImage
                          image={getImage(advert?.image?.localFile)}
                          alt={advert?.title}
                          title={advert?.title}
                          style={{ width: "100%" }}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (      
          <section className={`uk-section ${bg} uk-padding-remove`}>
          <div style={{ marginTop: "38px", marginBottom: "38px"}}>    
            <div className="uk-container" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <div className={`uk-grid uk-child-width-1-2@l uk-flex-middle`}>
                  <div
                    data-uk-scrollspy="cls: uk-animation-fade; delay: 700, repeat: true"
                    className="uk-flex-first@l"
                  >
                    {advert?.image && (
                      <GatsbyImage
                        image={getImage(advert?.image?.localFile)}
                        alt={advert?.title}
                        title={advert?.title}
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                  <div className="uk-flex-first">
                    <div className=" uk-margin-small-bottom">
                      <h4 className="uk-margin-remove" style={{marginBottom: "20px"}}>{advert?.title}</h4>
                    </div>
                    <div className=" uk-margin-small-bottom">
                      <h5 className="uk-margin-remove" style={{ color: "#687169", fontWeight: "500" }} >{advert?.heading}</h5>
                    </div>
                    <div className="uk-margin-small-top">
                      <Markdown children={advert?.content?.data?.content}/>
                    </div>
                    {advert.enabled === true ? (
                    <>
                    <Link
                      to={advert?.link}
                      alt={`button that links to our ${advert?.title}`}
                      title={`Click here to learn more about ${advert?.title}`}
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-visible@l"
                      //data-uk-icon="arrow-right"
                      style={{ borderColor: "#617F7F", color: "#617F7F", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}

                    >
                      {advert?.buttonName}
                      <span
                        className="uk-margin-small-left"
                        uk-icon="icon: arrow-right; ratio: 1"
                      ></span>                      
                    </Link>

                    <Link
                      to={advert?.link}
                      alt={`button that links to our ${advert?.title}`}
                      title={`Click here to learn more about ${advert?.title}`}
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-hidden@l"
                      //data-uk-icon="arrow-right"
                      style={{ borderColor: "#617F7F", color: "#617F7F", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}
                    >
                      <h5 className="uk-margin-small-right buttonLink">
                        {advert?.buttonName}
                      </h5>
                      <span
                        className="uk-margin-small-left"
                        uk-icon="icon: arrow-right; ratio: 1"
                      ></span>                     
                    </Link>
                    </>      
                    ) : null }
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
     </>
  )
}

export default PageAdvert
